import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { CallToAction } from "../components/CTA"

const SinglePatientExperience = ({ pageContext, location, data }) => {
  const post = data.allFeaturedPatientExperiencesJson.nodes[0]
  const callToAction = data.allTemplateSettingsJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  return (
    <Layout language={language} className="single-patient-experience">
      <SEO
        title={metaTitle}
        description={metaDescription}
        lang={language}
        pathname={location.pathname}
      />

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column">
            <Text as="h1" text={post.heading} className="px-40--mobile" />
            {post.mainVideo && post.mainVideo.youtube && (
              <div>
                <TheaterVideo
                  buttonClass="contained mx-auto main-video-button is-hidden-touch"
                  videoUrl={`https://www.youtube.com/watch?v=${post.mainVideo.youtube}`}
                  controls
                  playing
                  overVideo={true}
                  language={language}>
                  <Image
                    className="main-video-image px-40--mobile mt-2"
                    publicId={post.mainVideo.imageId}
                  />
                </TheaterVideo>
                <TheaterVideo
                  buttonClass="contained mx-auto--mobile is-hidden-desktop mobile-main-video-button"
                  videoUrl={`https://www.youtube.com/watch?v=${post.mainVideo.youtube}`}
                  controls
                  playing
                  language={language}
                />
              </div>
            )}
          </div>
        </Columns>
      </Section>

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column px-40--mobile">
            {post.bodySections.map((body, index) => (
              <div key={index}>
                <Text text={body.text.string} />
                {body.images && (
                  <div className="image-sections">
                    {body.images.map((img, idx) => (
                      <Image
                        key={idx}
                        publicId={img.image}
                        className="body-section-img"
                      />
                    ))}
                  </div>
                )}
                {post.caseVideo && post.caseVideo.youtube && (
                  <TheaterVideo
                    buttonClass="contained mx-auto main-video-button is-hidden-touch"
                    videoUrl={`https://www.youtube.com/watch?v=${post.caseVideo.youtube}`}
                    controls
                    playing
                    overVideo={true}
                    language={language}>
                    <Image
                      className="main-video-image px-40--mobile mt-2"
                      publicId={post.mainVideo.imageId}
                    />
                  </TheaterVideo>
                )}
              </div>
            ))}
          </div>
        </Columns>
      </Section>

      <Section zeroBottom className="before-after-background-img">
        <Columns sideColumnsSize={2}>
          <div className="column before-after-body-section">
            <Text as="h2" text="Before & After" />
            <div className="before-after-img-section px-40--mobile">
              <Image publicId={post.beforeAfterSection.beforeImage} />
              <Image publicId={post.beforeAfterSection.afterImage} />
            </div>
          </div>
        </Columns>
      </Section>

      <CallToAction
        colorBack
        heading={callToAction.getStarted.heading}
        blurb={callToAction.getStarted.blurb}
        buttons={callToAction.getStarted.buttons}
      />
    </Layout>
  )
}

export const featuredPatientExperiencePageQuery = graphql`
  query featuredPatientExperiencePage($title: String!) {
    allFeaturedPatientExperiencesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        mainVideo {
          imageId
          youtube
        }
        bodySections {
          images {
            image
          }
          text {
            string
          }
        }
        beforeAfterSection {
          beforeImage
          afterImage
        }
      }
    }
    allTemplateSettingsJson(filter: { templateType: { eq: "single-review" } }) {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              appearance
              buttonText
              destination
              href
            }
          }
        }
      }
    }
  }
`

export default SinglePatientExperience
